import React, { Fragment } from 'react'
import lintiLogo from '../Assets/Images/Spinner/logo-linti.svg'
import isitransLogo from '../Assets/Images/Spinner/logo-isitrans.jpg'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import '../styles/2-components/_loadingSpinner.scss'

export default function LoadingSpinner() {

    const { width } = useWindowDimensions();

    return (
        <div className="loading-spinner">
            <div className="loading-spinner__wrapper">
                {
                    width < 600
                        ? <Fragment>
                            <h1 className="loading-spinner__title">Asociación nicoleña</h1>
                            <h1 className="loading-spinner__title">de</h1>
                            <h1 className="loading-spinner__title">transportistas</h1>
                        </Fragment>
                        : <h1 className="loading-spinner__title">Asociación nicoleña de transportistas</h1>
                }
                <img
                    src={lintiLogo}
                    alt="logo linti"
                    className="loading-spinner__img"
                />
                <img
                    src={isitransLogo}
                    alt="logo isitrans"
                    className="loading-spinner__img"
                />
            </div>
        </div>
    )
}
