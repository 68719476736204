import Whatsapp from "../Assets/Icons/Footer/whatsapp.svg";
import Mail from "../Assets/Icons/Footer/gmail.svg";
import Facebook from "../Assets/Icons/Footer/facebook.svg";

const socials = [
  {
    iconImg: Whatsapp,
    link: "https://api.whatsapp.com/send/?phone=5493364679097&text&app_absent=0",
    shortLink: "+5493364679097",
  },
  {
    iconImg: Facebook,
    link: "https://www.facebook.com/cursoschoferes.sannicolas",
    shortLink: "cursoschoferes.sannicolas",
  },
  {
    iconImg: Mail,
    link: "mailto:cursoactualizacionsn@gmail.com?subject=Consulta%20desde%20sitio%20web&body=Escriba%20aquí%20tu%20consulta...%0A%0APuede%20adjuntar%20archivos%20si%20lo%20desea.%20",
    shortLink: "cursoactualizacionsn@gmail.com",
  },
];

const links = ["inicio", "contacto", "ubicación"];

export { socials, links };
