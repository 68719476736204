import React from 'react'

export default function FormInputs(props) {
    return (
        <div>
            <div className="input-box">
                <input
                    type={props.type}
                    name={props.name}
                    id={props.name}
                    required
                />
                <label htmlFor={props.name}>{props.label}</label>
            </div>
        </div>
    )
}
