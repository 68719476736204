import React from 'react'
import Title from '../Components/Title'
import ContactForm from '../Layout/Contact/ContactForm'
import '../styles/4-views/_contact.scss'

function contact(props) {

    return (
        <section
            className="contact"
            id="contact"
        >
            <div className="contact__wrapper">
                <Title section="contact" />
                <ContactForm onSubmit={e => props.onSubmit(e)} />
            </div>
        </section>
    )
}

export default contact