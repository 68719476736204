import emailjs from "emailjs-com";

export default function sendEmail(e) {
  return emailjs
    .sendForm(
      "service_kd8sbcj",
      "template_m0pw2vu",
      e.target,
      "user_Gy6VCi5xN3xifflkE64ds"
    )
    .then(
      result => {
        console.log(result.text);
        return result;
      },
      error => {
        console.log(error.text);
        return error;
      }
    );
}
