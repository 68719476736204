export default function LinkRef(value) {
  switch (value) {
    case "inicio":
      return "#home";
    case "ubicación":
      return "#location";
    case "contacto":
      return "#contact";
    default:
      break;
  }
}
