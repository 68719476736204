import slide1 from "../Assets/Images/Carousel/slide1.jpg";
import slide2 from "../Assets/Images/Carousel/slide2.jpeg";
import slide3 from "../Assets/Images/Carousel/slide3.jpeg";
import slide4 from "../Assets/Images/Carousel/slide4.jpeg";
import slide5 from "../Assets/Images/Carousel/slide5.jpeg";
import slide11 from "../Assets/Images/Carousel/slide11.jpg";
import slide22 from "../Assets/Images/Carousel/slide22.jpeg";
import slide33 from "../Assets/Images/Carousel/slide33.jpeg";
import slide44 from "../Assets/Images/Carousel/slide44.jpeg";
import slide55 from "../Assets/Images/Carousel/slide55.jpeg";

const carouselContent = [
  {
    slide: slide1,
    slide2: slide11,
    wSlide: "430w",
    wSlide2: "596w",
  },
  {
    slide: slide2,
    slide2: slide22,
    wSlide: "430w",
    wSlide2: "596w",
  },
  {
    slide: slide3,
    slide2: slide33,
    wSlide: "592w",
    wSlide2: "929w",
  },
  {
    slide: slide4,
    slide2: slide44,
    wSlide: "592w",
    wSlide2: "933w",
  },
  {
    slide: slide5,
    slide2: slide55,
    wSlide: "592w",
    wSlide2: "933w",
  },
];

export default carouselContent;
