export default function TitleText(section) {
  switch (section) {
    case "contact":
        return "contacto"
    case "location":
        return "nuestra ubicación"
    case "products":
        return "nuestros productos"
    default:
      break;
  }
}
