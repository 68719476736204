const inputsContentArr = [
  {
    type: "text",
    name: "from_name",
    label: "nombre y apellido",
  },
  // {
  //   type: "email",
  //   name: "reply_to",
  //   label: "E-mail",
  // },
  {
    type: "tel",
    name: "phone_to",
    label: "Teléfono",
  },
];

export default inputsContentArr;
