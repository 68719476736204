import React, { useState, Fragment } from 'react'
import Drawer from '../../Components/Drawer'
import NavBar from '../../Components/NavBar'
import MenuX from '@material-ui/icons/Close'
import MenuBars from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import Logo from '../../Assets/Images/logo.png'
import useWindowDimensions from '../../Hooks/UseWindowDimensions'
import '../../styles/3-layout/home/_nav.scss'


function Nav(props) {

    const { width } = useWindowDimensions();

    const [toggle, setToggle] = useState(false)

    function handleClick(e) {
        setToggle(!toggle)
        props.onClick(e)
    }

    function handleScrollToTop() {
        window.scrollTo(0, 0)
    }

    return (
        <nav className="nav">
            <div className="nav__wrapper">
                <div
                    className="nav__brand"
                    onClick={() => handleScrollToTop()}
                >
                    <div className="nav__logo-box">
                        <img
                            src={Logo}
                            alt="logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="nav__company-name">
                        {
                            width > 768
                                ? <h1 className="company__name"> <span>Cursos Linti</span> San Nicolás</h1>
                                : <h1 className="company__name"> <span>Cursos Linti</span> SN</h1>
                        }
                    </div>
                </div>
                {
                    width < 1024
                        ? <Fragment>
                            <Button
                                aria-label="menu button"
                                onClick={() => handleClick()}
                                id="menu-button"
                                className="nav__menu-button"
                                variant="outlined"
                            >
                                {
                                    toggle
                                        ? <MenuX className="nav__button-icon" />
                                        : <MenuBars className="nav__button-icon" />
                                }
                            </Button>
                            <Drawer
                                open={toggle}
                                onClick={e => handleClick(e)}
                            />
                        </Fragment>
                        : <Fragment>
                            <NavBar
                                onClick={e => handleClick(e)}
                            />
                            {toggle && setToggle(false)}
                        </Fragment>
                }
            </div>
        </nav>
    )
}

export default Nav
